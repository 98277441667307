import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export function Header(){
    const [isLoggedIn, setIsLoggedIn] = useState(document.cookie.includes('TUID'));
    // const URL = "http://localhost"
    const URL = "https://special-share.org"

    useEffect(() => {
        const interval = setInterval(() => {
            setIsLoggedIn(document.cookie.includes('TUID'));
        }, 1000); // Check every second

        return () => clearInterval(interval);
    }, []);

    const openPopup = (url) => {
      window.open(url, 'popUpWindow', 'height=800,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
    };

    return(
        <div>

<section className="nav-bar-section">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark mt-3">
            <div className="container-fluid">
              <Link className="navbar-brand fw-bolder scale-up-center" to="/" ><span><img alt="img" className="navbar-logo rounded-circle position-relative" src="https://special-share.org/assets/images/navbarlogo.png" width="60px" height="auto" /></span> SpecialShare <span className="brand-dot">.</span></Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link to="/" className="nav-link active-nav-item">Home</Link>
                  </li>
                  <li className="nav-item">
                  <Link to="/discover" className="nav-link">Discover</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/commands" className="nav-link">Commands</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href onClick={() => {window.open('https://special-share.org/support','popUpWindow','height=800,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'); }}>Support</a>
                  </li>
                  <li className="nav-item">
                    <Link to="/Shards" className="nav-link">Shards</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/premium" className="nav-link" style={{color: '#ff4aa1'}}>Premium</Link>
                  </li>
                </ul>
                {/* <div className="btn-group dropstart mb0">
                        <button className="btn btn-secondary dr-tg" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="assets/images/english.png" width="35px" height="35px"/>
                        </button>
                        <ul className="dropdown-menu">
                           <li><a className="dropdown-item text-light" href="#" onClick="language('AR')"><img src="assets/images/arabic.png" width="25px" height="25px"/>&nbsp;&nbsp; Arabic</a></li>
                           <li><a className="dropdown-item text-light" href="#" onClick="language('EN')"><img src="assets/images/english.png" width="25px" height="25px"/>&nbsp;&nbsp; English</a></li>
                        </ul>
                     </div> */}
                <div className="d-flex">
                    {isLoggedIn ? (
                        <button id="DASHBOARD" className="btn btn-primary-custom" onClick={() => { window.location.href = `${URL}/dashboard`; }}>Dashboard</button>
                      ) : (
                      <button id="LOGIN" className="btn btn-primary-custom" onClick={() => openPopup(`https://discord.com/oauth2/authorize?prompt=none&client_id=751151926959276050&redirect_uri=${URL}/dashboard%2Flogin&response_type=code&scope=guilds+identify+guilds.join`)}>Login</button>
                    )}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>


        </div> 
    )
}