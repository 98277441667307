import React from 'react'
import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';

export function Loading(){

    return(
        <div>
          <div className="row">
                  <div className="col-md-4 my-3">
                    <div className="card2 border-0 server__card">
                      <img alt="" className="img-fluid user-image d-inline-block skeleton img-fluid server__banner" />
                      <div className="card-body mt-2">
                        <img alt="" width="85px" height="85px" className="img-fluid user-image d-inline-block skeleton server__icon" />

                        <div className="row">
                          <div className="col-md-6">
                            {/* <h5 className="text-start server__name mt-3 skeleton_name"></h5> */}
                          </div>
                        </div>

                          <p className="text-start server_descreption skeleton_descreption mt-3"></p></div>
                          <div className="row p-3">
                          <div className="skeleton_buttons"></div>
                          <div className="skeleton_buttons"></div>


                          </div>
                        </div>
                  </div>

                  <div className="col-md-4 my-3">
                    <div className="card2 border-0 server__card">
                      <img alt="" className="img-fluid user-image d-inline-block skeleton img-fluid server__banner" />
                      <div className="card-body mt-2">
                        <img alt="" width="85px" height="85px" className="img-fluid user-image d-inline-block skeleton server__icon" />

                        <div className="row">
                          <div className="col-md-6">
                            {/* <h5 className="text-start server__name mt-3 skeleton_name"></h5> */}
                          </div>
                        </div>

                          <p className="text-start server_descreption skeleton_descreption mt-3"></p></div>
                          <div className="row p-3">
                          <div className="skeleton_buttons"></div>
                          <div className="skeleton_buttons"></div>


                          </div>
                        </div>
                  </div>

                  <div className="col-md-4 my-3">
                    <div className="card2 border-0 server__card">
                      <img alt="" className="img-fluid user-image d-inline-block skeleton img-fluid server__banner" />
                      <div className="card-body mt-2">
                        <img alt="" width="85px" height="85px" className="img-fluid user-image d-inline-block skeleton server__icon" />

                        <div className="row">
                          <div className="col-md-6">
                            {/* <h5 className="text-start server__name mt-3 skeleton_name"></h5> */}
                          </div>
                        </div>

                          <p className="text-start server_descreption skeleton_descreption mt-3"></p></div>
                          <div className="row p-3">
                          <div className="skeleton_buttons"></div>
                          <div className="skeleton_buttons"></div>


                          </div>
                        </div>
                  </div>
              </div>
        </div> 
    )
}