import React, { useState } from 'react';
import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';
import 'font-awesome/css/font-awesome.min.css';
import { Header } from '../../component/Header'
import { Footer } from '../../component/Footer'
import SweetAlert2 from 'react-sweetalert2';
import { GetShards } from '../../utilts/api'
import Swal from 'sweetalert2'

export function Shards(){
  const [ Shards, SetShards] = React.useState([])
  const [ ShardsInfo, SetShardsInfo] = React.useState([])
  const [swalProps] = useState({});

  React.useEffect(() => {
  
    GetShards()
    .then(({data}) => SetShards(data[1]))
    GetShards()
    .then(({data}) => SetShardsInfo(data[0]))

  }, [])

  var arr = []
  Object.keys(Shards).forEach(function(key) {
    arr.push(Shards[key]);
  });

  function SweetAlert(HTML, TITLE){
      Swal.fire({
        show: true,
        title: TITLE,
        html: HTML,
      })
  }
// console.log(arr)
    return(

    <div>
        <Header />
        <link href="https://cdn.jsdelivr.net/npm/@sweetalert2/theme-dark/dark.css" rel="stylesheet"></link>
        <section style={{marginTop: '6%', marginBottom: '6%'}}>
          <div className="container">
            <h2 className="text-center features-title">Shards</h2>
            <center><div className="features-main-title center" /></center>
            <h2 className="main-title-terms"><strong>Shards [{ShardsInfo.SHARDS}]</strong></h2>
            <p className="main-title-terms" style={{color: '#b0b4c7'}}>Avg Ping: {ShardsInfo.AVG} ms</p>
            <center>
              <div className="row mt-5">
      
              {
                
              arr.map((data, key) => (
           
                <div className="col-md-3 my-2">
                  <div className="card border-0 partner-card">
                    <div className="card-body mt-2">
                      <h4 className="text-center mt-1">{key}</h4>
                      <center><div className="features-main-title center" /></center>
                      <h2 className="text-center partner-descreption mt-3"> {data.LATENCY} ms</h2>
                    </div>
                    <div className="card-footer p-3">
                      {/* <a href="" 
                      onClick={() => {
                        SweetAlert(`<div class="text-start"> Ping: ${data.LATENCY}ms<br>Status: ${data.STATUS}<br>Guilds: ${data.GUILD_COUNT}<br>Channels: ${data.CHANNELS}<br>Users: ${data.USERS}</div> `, `Shard ${key}`)
                      }}
                      > */}
                            
                      {
                        data.STATUS === "Offline" ? (
                          <button className="btn btn-view w-100 text-light" style={{background: 'red'}}                       onClick={() => {
                            SweetAlert(`<div class="text-start"> Ping: ${data.LATENCY}ms<br>Status: ${data.STATUS}<br>Guilds: ${data.GUILD_COUNT}<br>Channels: ${data.CHANNELS}<br>Users: ${data.USERS}</div> `, `Shard ${key}`)
                          }}>More info</button>
                        ) : (
                          <button className="btn btn-view w-100 text-light" onClick={() => {
                            SweetAlert(`<div class="text-start"> Ping: ${data.LATENCY}ms<br>Status: ${data.STATUS}<br>Guilds: ${data.GUILD_COUNT}<br>Channels: ${data.CHANNELS}<br>Users: ${data.USERS}</div> `, `Shard ${key}`)
                          }}>More info</button>
                        )
                      }
                            
                      {/* </a> */}
            <SweetAlert2 {...swalProps} />
                    </div>
                  </div>
                </div>

              ))

            }

              </div>
            </center>
          </div>
        </section>
                
        <Footer />
        
      </div> 
    )
}