import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';
import '../../assets/css/test.css';
import '../../assets/css/view_server.css';
import 'font-awesome/css/font-awesome.min.css';
import { Header } from '../../component/Header';
import { Footer } from '../../component/Footer';
import { GetServerByID, ServerVoting, CreateInvite } from '../../utilts/api';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { useCookies } from 'react-cookie';
import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2';
import Tooltip from '@mui/material/Tooltip';
import ReactMarkdown from 'react-markdown';
import remarkEmoji from 'remark-emoji';

export function DiscoverView() {
  const [swalProps] = useState({});
  const [nothing] = useState(null);
  const [ViewServer, SetViewServer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies();
  const [TUID, setTUID] = useState(() => {
    const cookieValue = document.cookie.split('; ').find(row => row.startsWith('TUID='));
    return cookieValue ? decodeURIComponent(cookieValue.split('=')[1]) : null;
  });
  const [showReCAPTCHA, setshowReCAPTCHA] = useState(false);
  const [showButtonVote, setshowButtonVote] = useState(true);

  // const URL = "http://localhost";
  const URL = "https://special-share.org"

  useEffect(() => {
    const UEL = window.location.href;
    const ID_SERVER = UEL.substring(UEL.indexOf('/discover/view/') + 15);

    setLoading(true);
    GetServerByID(ID_SERVER).then(({ data }) => SetViewServer(data))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const cookieValue = document.cookie.split('; ').find(row => row.startsWith('TUID='));
      setTUID(cookieValue ? decodeURIComponent(cookieValue.split('=')[1]) : null);
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, []);

  const openPopup = (url) => {
    window.open(url, 'popUpWindow', 'height=800,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
  };

  function Voteing() {
    if (TUID != null) {
      setshowReCAPTCHA(true);
      setshowButtonVote(false);
    } else {
      SweetAlert("info", "Must Login");
      openPopup(`https://discord.com/oauth2/authorize?prompt=none&client_id=751151926959276050&redirect_uri=${URL}/dashboard%2Flogin&response_type=code&scope=guilds+identify+guilds.join`);
    }
  }

  function SweetAlert(ICON, TITLE) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      background: '#333', // لون الخلفية
      color: '#fff', // لون النص
      customClass: {
        popup: 'colored-toast' // فئة CSS مخصصة
      },
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });

    Toast.fire({
      icon: ICON,
      title: TITLE
    });
  }

  function onChange(value) {
    const UEL = window.location.href;
    const ID_SERVER = UEL.substring(UEL.indexOf('/discover/view/') + 15);

    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timerProgressBar: false,
      background: '#333', // لون الخلفية
      color: '#fff', // لون النص
      customClass: {
        popup: 'colored-toast' // فئة CSS مخصصة
      },
      didOpen: () => {
        Swal.showLoading();
      }
    });

    Toast.fire({
      icon: "info",
      title: "Please wait"
    });

    ServerVoting(TUID, value, ID_SERVER).then(({ data }) => SweetAlert(data.TEXT_TYPE, data.response));
    setTimeout(() => {
      setshowReCAPTCHA(false);
      setshowButtonVote(true);
    }, 5000);
  }
  function CreateInviteNow() {
    const UEL = window.location.href;
    const ID_SERVER = UEL.substring(UEL.indexOf('/discover/view/') + 15);
    CreateInvite(TUID, ID_SERVER).then(({ data }) => {
      if (!data.error) {
        window.open(data.invite, '_blank');
      } else {
        SweetAlert("error", data.error);
      }
    });
  }

    return (
      <div>
        <Header />
        <SweetAlert2 {...swalProps} />
        {
          loading ? (
            <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </center>
          ) : (
            ViewServer.length === 0 ? (
              <center>
                <div style={{ marginTop: '100px', marginBottom: '100px' }}>
                  <i className="fa fa-exclamation-triangle" style={{ fontSize: '50px', color: '#ff4aa1' }}></i>
                  <h2 style={{ color: '#ff4aa1', marginTop: '20px' }}>No Results Found</h2>
                  <p style={{ color: '#ccc', fontSize: '18px' }}>The server you are looking for does not exist or has been removed.</p>
                  <Link to="/discover" style={{ textDecoration: 'none' }}>
                    <button style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#ff4aa1', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                      <i className="fa fa-arrow-left" style={{ marginRight: '10px' }}></i>
                      Back
                    </button>
                  </Link>
                </div>
              </center>
            ) :
              ViewServer.map((data) => (
                <div className="server-continer" key={data.ID}>
                  <div className="server">
                    <Link to={`/discover`} style={{ color: '#FFF' }}>
                      <button className="button3"><i className="fa fa-arrow-left" /></button>
                    </Link>
                    <img alt="img" className="server-banner" src={data.BANNER} />
                    <div className="server-info2">
                      <div className="server-icon">
                        <img alt="img" id="img" src={data.ICON} />
                        <div className="name-tags">
                          <a href>{data.NAME}</a>
                          <div className="tags">
                            <a href>{data.TYPE[0]}</a>
                            {
                              data.TYPE[1] !== undefined ? (
                                <a href>{data.TYPE[1]}</a>
                              ) : (
                                nothing
                              )
                            }
                          </div>
                        </div>
                      </div>
                      <div className="buttons">
                        <a  style={{ fontSize: 'inherit' }}>
                          <button className="button1" onClick={CreateInviteNow} style={{ backgroundColor: data.COLOR }}>Join</button>
                        </a>
                        {showReCAPTCHA ?
                          <ReCAPTCHA
                            sitekey="6LeWfakZAAAAACPuUV0_RGdFxvN_9M_Pym1yQmYo"
                            theme="dark"
                            hl="ar"
                            size="compact"
                            onChange={onChange}
                          />
                          : null}
                        {showButtonVote ?
                          <button className="button2" onClick={Voteing} id="VOTE">Vote</button>
                          : null}
                      </div>
                    </div>
                    <div className="server-description-continer">
                      <div className="description">
                        <ReactMarkdown
                          remarkPlugins={[remarkEmoji]}
                        >
                          {data.DESCRIPTION}
                        </ReactMarkdown>
                      </div>
                      <div className="info">
                        <h4>Overview</h4>
                        <div>
                          <svg width="16px" height="16px" className="svg-inline--fa fa-caret-square-up fa-w-14" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="caret-square-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><g className="fa-group"><path className="fa-secondary" fill="currentColor" d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-59.4 288H107.4c-10.14 0-15.21-14.07-8.05-22.29L216 163.82a10.38 10.38 0 0 1 16.08 0l116.57 133.89c7.16 8.22 2.09 22.29-8.05 22.29z"></path><path className="fa-primary" fill="currentColor" d="M340.6 320H107.4c-10.14 0-15.21-14.07-8.05-22.29L216 163.82a10.38 10.38 0 0 1 16.08 0l116.57 133.89c7.16 8.22 2.09 22.29-8.05 22.29z"></path></g></svg>
                          &nbsp; <a href>{data.COUNT_VOTE} Votes</a>
                        </div>
                        <div>
                          <svg width="16px" height="16px" className="svg-inline--fa fa-portrait" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="portrait" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><g className="fa-group"><path className="fa-secondary" fill="currentColor" d="M336 0H48A48 48 0 0 0 0 48v416a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V48a48 48 0 0 0-48-48zM192 128a64 64 0 1 1-64 64 64.06 64.06 0 0 1 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H102.4C90 384 80 375.4 80 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5a103 103 0 0 0 79.6 0h5c37.1 0 67.2 25.8 67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6z"></path><path className="fa-primary" fill="currentColor" d="M192 256a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm44.8 32h-5a103 103 0 0 1-79.6 0h-5c-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6z"></path></g></svg>
                          &nbsp;
                          <a href>{data.MEMBER_COUNT} Members</a>
                        </div>
                        <div className="badges">
                          {
                            data.BADGES["PREMIUM"] === 1 ? (
                              <a href><Tooltip title="Premium" placement="bottom" disableFocusListener><img alt="img" className="verified-badge" id="premium" src="https://cdn.discordapp.com/emojis/1076937105621659739.webp?size=20" /></Tooltip></a>
                            ) : (
                              nothing
                            )
                          }
                          {
                            data.BADGES["VERIFIED"] === 1 ? (
                              <a href><Tooltip title="Verified" placement="bottom" disableFocusListener><img alt="img" className="verified-badge" id="verified" src="https://cdn.discordapp.com/emojis/1076937092891955360.webp?size=20" /></Tooltip></a>
                            ) : (
                              nothing
                            )
                          }
                          {
                            data.BADGES["PARTNER"] === 1 ? (
                              <a href><Tooltip title="Partner" placement="bottom" disableFocusListener><img alt="img" className="verified-badge" id="partner-ver" src="https://cdn.discordapp.com/emojis/1076937098642346014.webp?size=20" /></Tooltip></a>
                            ) : (
                              nothing
                            )
                          }
                          <br /><br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          )
        }
        <br />
        <Footer />
      </div>
    );
  }