import React, { useState } from 'react';
// import { getUserDetals } from '../../utilts/api'
// import { Link } from 'react-router-dom'
import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';
import 'font-awesome/css/font-awesome.min.css';
import { Header } from '../../component/Header'
import { Footer } from '../../component/Footer'
import '../../assets/css/commands.css';

export function Commands(){

    return(

    <div>
        <Header />

        <div className="container p-4 mb-5 rounded" style={{marginTop: '3%', backgroundColor: '#272938'}}>
        <section className="top-section">
          <h2 className="main-title-terms"><strong>Commands Bot</strong></h2>
          <center>
            <div className="commands-section">
              <div className="command-card card mt-4">
                <div className="row"> 
                  <div className="col-md-4 text-center">
                    <div className="card command-name">
                      <p className="fw-bold mt-4">/share</p>
                    </div> 
                  </div>
                  <div className="col-md-8 command-descreption p-3">
                    <p className="mt-2">Share your server 🔄</p>
                  </div>
                </div>
              </div>
              <div className="card command-card mt-4">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <div className="card command-name">
                      <p className="fw-bold mt-4">/infobot</p>
                    </div> 
                  </div>
                  <div className="col-md-8 command-descreption p-3">
                    <p className="mt-2">Information about the bot 💡</p>
                  </div>
                </div>
              </div>
              <div className="card command-card mt-4">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <div className="card command-name">
                      <p className="fw-bold mt-4">/preview</p>
                    </div> 
                  </div>
                  <div className="col-md-8 command-descreption p-3">
                    <p className="mt-2">Show share appearance 👀</p>
                  </div>
                </div>
              </div>
              <div className="card command-card mt-4">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <div className="card command-name">
                      <p className="fw-bold mt-4">/invite</p>
                    </div> 
                  </div>
                  <div className="col-md-8 command-descreption p-3">
                    <p className="mt-2">invite bot for your server 🤖</p>
                  </div>
                </div>
              </div>
              <div className="card command-card mt-4">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <div className="card command-name">
                      <p className="fw-bold mt-4">/sp</p>
                    </div> 
                  </div>
                  <div className="col-md-8 command-descreption p-3">
                    <p className="mt-2">Shows your or someone's SP 💳</p>
                  </div>
                </div>
              </div>
              <div className="card command-card mt-4">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <div className="card command-name">
                      <p className="fw-bold mt-4">/server</p>
                    </div> 
                  </div>
                  <div className="col-md-8 command-descreption p-3">
                    <p className="mt-2">Information about the server 💡</p>
                  </div>
                </div>
              </div>
              <div className="card command-card mt-4">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <div className="card command-name">
                      <p className="fw-bold mt-4">/channel</p>
                    </div> 
                  </div>
                  <div className="col-md-8 command-descreption p-3">
                    <p className="mt-2">To change the sharing channel </p>
                  </div>
                </div>
              </div>
              <div className="card command-card mt-4">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <div className="card command-name">
                      <p className="fw-bold mt-4">/reminder</p>
                    </div> 
                  </div>
                  <div className="col-md-8 command-descreption p-3">
                    <p className="mt-2">Server sharing reminder </p>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </section>
      </div> 

        <Footer />
        
      </div> 
    )
}