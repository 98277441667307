import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';
import '../../assets/css/commands.css';
import 'font-awesome/css/font-awesome.min.css';
import { Header } from '../../component/Header'
import { Footer } from '../../component/Footer'


export function premium(){

    return(

    <div>
        <Header />

        <section style={{marginTop: '6%'}}>
          <div className="container">
            <h2 className="text-center features-title">Premium</h2>
            <p className="text-center" style={{color: '#b0b4c7'}}>Let Bot Share Your Server Automatically (:</p>
            <center><div className="features-main-title center" /></center><br></br>
            <center>
              <div className="row">
              <div className="col-md-3 my-2">
                </div>
                <div className="col-md-3 my-2">
                  <div className="card border-0 partner-card">
                    <div className="card-body mt-2">
                      <h4 className="text-center mt-1">Month</h4>
                      <center><div className="features-main-title center" />ㅤㅤ</center>
                      <h2 className="text-center partner-descreption mt-3"> 2.99$</h2>
                    </div>
                    <div className="card-footer p-3">
                      <a href="/dashboard/Premium/"><button className="btn btn-view w-100 text-light">Get Now</button></a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3 my-2">
                  <div className="card border-0 partner-card rounded img-1">
                    <div className="card-body mt-2">
                      <h4 className="text-center mt-1">Month 3</h4>
                      <center><div className="features-main-title center" /></center>
                      <h2 className="text-center partner-descreption mt-3"> 11.99$</h2>
                    </div>
                    <div className="card-footer p-3">
                      <a href="/dashboard/Premium/"><button className="btn btn-view w-100 text-light">Get Now</button></a>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-3 my-2">
                  <div className="card border-0 partner-card">
                    <div className="card-body mt-2">
                      <h4 className="text-center mt-1">Month 6</h4>
                      <center><div className="features-main-title center" />save 4%</center>
                      <h2 className="text-center partner-descreption mt-3"> 22.99$</h2>
                    </div>
                    <div className="card-footer p-3">
                      <a href="/dashboard/Premium/"><button className="btn btn-view w-100 text-light">Get Now</button></a>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-3 my-2">
                  <div className="card border-0 partner-card">
                    <div className="card-body mt-2">
                      <h4 className="text-center mt-1">Year</h4>
                      <center><div className="features-main-title center" />Off 15%</center>
                      <h2 className="text-center partner-descreption mt-3"> 29.99$</h2>
                    </div>
                    <div className="card-footer p-3">
                      <a href="/dashboard/Premium/"><button className="btn btn-view w-100 text-light">Get Now</button></a>
                    </div>
                  </div>
                </div>

           


                </div>
            </center>
          </div>
        </section>
        <section className="partners-section p-5">
          <center><h1 className="fw-bolder features-main-title">FEATURES PREMIUM</h1>
            <div className="row st-row w-50">
              <div className="col-md-6 my-1">
                <div className="card card-server sr-2 my-2 p-4">
                  <lord-icon className="navbar-logo rounded-circle position-relative" src="https://cdn.lordicon.com/udwhdpod.json" trigger="loop" colors="primary:#ff4aa1,secondary:#ff4aa1" style={{width: '80px', height: 'auto'}}>
                  </lord-icon>
                  <div className="server-info d-inline-block">
                    <h6 className="text-light">Automatic sharing</h6>
                    <span className="server-info"> There is no need to write a share command anymore (: Automatic sharing every 6 hours</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 my-1">
                <div className="card card-server sr-2 my-2 p-4">
                  <lord-icon className="navbar-logo rounded-circle position-relative" src="https://cdn.lordicon.com/fgkmrslx.json" trigger="loop" colors="primary:#e4e4e4,secondary:#ff4aa1" style={{width: '80px', height: 'auto'}}>
                  </lord-icon>
                  <div className="server-info d-inline-block">
                    <h6 className="text-light">Upload Banner</h6>
                    <span className="server-info "> Upload banner that matches your server appearance</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 my-1">
                <div className="card card-server sr-2 my-2 p-4">
                  <lord-icon className="navbar-logo rounded-circle position-relative" src="https://cdn.lordicon.com/wloilxuq.json" trigger="loop" colors="primary:#e4e4e4,secondary:#ff4aa1" style={{width: '80px', height: 'auto'}}>
                  </lord-icon>
                  <div className="server-info d-inline-block">
                    <h6 className="text-light">Change Color</h6>
                    <span className="server-info "> Change the colors of your server share to a color you like</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 my-1">
                <div className="card card-server sr-2 my-2 p-4">
                  <lord-icon className="navbar-logo rounded-circle position-relative" src="https://cdn.lordicon.com/rivoakkk.json" trigger="loop" colors="primary:#e4e4e4,secondary:#ff4aa1" style={{width: '80px', height: 'auto'}}>
                  </lord-icon>
                  <div className="server-info d-inline-block">
                    <h6 className="text-light">Delete room sharing</h6>
                    <span className="server-info "> Get rid of servers sharing your server income</span>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </section>

        <Footer />
        
      </div> 
    )
}