import React from 'react'
import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';
import { Header } from '../../component/Header'
import { Footer } from '../../component/Footer'

export function NotFoundPage(){

    return(
        <div>
          <Header />
          
          <br></br><br></br><br></br><br></br><br></br><br></br>
          <center>
          <h1 className="main-title fw-bolder">Not Found This Page</h1>
          </center>
          <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>


          <Footer />
        </div> 
    )
}