import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';
import 'font-awesome/css/font-awesome.min.css';
import { Header } from '../../component/Header';
import { Footer } from '../../component/Footer';

export function refund() {
  return (
    <div>
      <Header />
      <section>
        <div
          className="container p-4 mb-5 rounded"
          style={{ marginTop: '3%', backgroundColor: '#272938' }}
        >
          <section className="top-section">
            <h2 className="main-title-terms">
              <strong>Refund Policy</strong>
            </h2>
            <p>
              This Refund Policy describes our general policies regarding handling refunds in general and does not serve as a legal base, nor is it approved by professionals. It lays down the foundations for any decision made upon requests. Any point listed here might be a reason for permitted initiations of any refund.
            </p>
            <h3><strong>Possible Reasons</strong></h3>
            <p>Listed below are possible reasons that might permit a full or prorated refund initiation:</p>
            <ul>
              <li>Any unauthorized transaction was not undertaken on your behalf but financially affected you.</li>
              <li>Every situation in which you are not successfully receiving or unable to access promised premium perks.</li>
            </ul>
            <p>All of the reasons above require viable proof to be accepted as possible inquiries for initiating a refund.</p>
            
            <h3><strong>Non-Acceptable Inquiries</strong></h3>
            <p>Below are situations where any refund will not be initiated due to non-acceptable reasons:</p>
            <ul>
              <li>Any situation in which you actively or passively forgot to cancel your ongoing subscription.</li>
              <li>Any further non-believable reason without feasible argumentation.</li>
            </ul>
            <p>Please note that we cannot accept inquiries in the situations listed above due to financial reasons.</p>

            <h3><strong>Important Notes</strong></h3>
            <p>
              To successfully reach an agreement, please remember to behave patiently and pleasantly. Performing a chargeback on behalf of the payment processor your transaction was handled through will not work out in your favor! In most situations, we can fight such cases to maintain our rights. Without prior contact with the team behind Special Share, you might end up being excluded from the usage of our services indefinitely.
            </p>
            <p>When staying friendly and courteous, you might end up being served with a refund regardless of the non-application of the stated above points.</p>
          </section>
        </div>
      </section>
      <Footer />
    </div>
  );
}
