import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';
import '../../assets/css/servers.css';
import '../../assets/css/test.css';
import 'font-awesome/css/font-awesome.min.css';
import { Header } from '../../component/Header'
import { Footer } from '../../component/Footer'
import { Loading } from '../../component/loading'
import { GetDiscover, GetTypes, GetDiscoverTypes, GetDiscoverNewServers } from '../../utilts/api'
import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import ReactMarkdown from 'react-markdown';
import remarkEmoji from 'remark-emoji';

export function Discover(){

  const [ TopServers, SetTopServers ] = React.useState([])
  // const [ DiscoverTypes, SetDiscoverTypes ] = React.useState([])
  const [ DiscoverTypes1, SetDiscoverTypes1 ] = React.useState([])
  const [ DiscoverTypes2, SetDiscoverTypes2 ] = React.useState([])
  const [ DiscoverNewServers, SetDiscoverNewServers ] = React.useState([])
  const [ types, setTypes ] = React.useState([])
  const [loading, setLoading] = useState(false);
  const [LoadingType_2, setLoadingType_2] = useState(false);
  const [LoadingType_1, setLoadingType_1] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [LoadingNewServers, setLoadingNewServers] = useState(false);
  const shouldAddEventHandler = true;

  const nothing = null;


  const [message, setMessage] = useState(null);


  const handleChange = event => {
    setMessage(event.target.value);
  };

  React.useEffect(() => {
    setLoadingNewServers(true);
    setLoadingNewServers(true);
    setLoadingTypes(true);
    setLoadingType_1(true);
    setLoadingType_2(true);
    

    GetTypes().then(({data}) => setTypes(data))
    .finally(() => {
      setLoadingTypes(false);
    });


    const UEL = window.location.href
    // const CHECK_TYPE = UEL.substring(UEL.indexOf('/discover/types/') + 16);
    const CheckIFTypes = UEL.includes("types");

    if(CheckIFTypes){
      // setLoading(true);
      // GetDiscoverTypes(`${CHECK_TYPE}`).then(({data}) => SetDiscoverTypes(data))
      // .finally(() => {
      //   setLoading(false);
      // });
    }else{
      setLoading(true);
      GetDiscover().then(({data}) => SetTopServers(data))
      .finally(() => {
        setLoading(false);
      });
    }
    
  }, [])

function GetServersByType(CHECK_TYPE)  {

  if(CHECK_TYPE.target.outerText !== "All"){
    // setLoading(true);
    // GetDiscoverTypes(`${CHECK_TYPE.target.outerText}`).then(({data}) => SetDiscoverTypes(data))
    // .finally(() => {
    //   setLoading(false);
    // });

  }else{
      setLoading(true);
      GetDiscover().then(({data}) => SetTopServers(data))
      .finally(() => {
        setLoading(false);
      });
  }
}

  const [offset, setOffset] = React.useState(null);
  const [ShowNewServers, setShowNewServers] = React.useState(false);
  const [ShowType_1, setShowType_1] = React.useState(false);
  const [ShowType_2, setShowType_2] = React.useState(false);
  
  const setScroll = () => {
    setOffset(window.scrollY);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

    if(ShowNewServers === false){
      if(offset >= 800){
          setShowNewServers(true)
          GetDiscoverNewServers().then(({data}) => SetDiscoverNewServers(data))
          .finally(() => {
            setLoadingNewServers(false);
          });
      }
  }
  if(ShowType_1 === false){
    if(offset >= 1800){
      setShowType_1(true)
      GetDiscoverTypes("Community").then(({data}) => SetDiscoverTypes1(data))
      .finally(() => {
        setLoadingType_1(false);
      });
    }
  }

  if(ShowType_2 === false){
    if(offset >= 2000){
      setShowType_2(true)
      GetDiscoverTypes("Gaming").then(({data}) => SetDiscoverTypes2(data))
      .finally(() => {
        setLoadingType_2(false);
      });
    }
  }

    return(

    <div>
        <Header />
        
        <section>
        <div className="container2 w-75">
          <div className="filter__container mt-5">
            <h6 className="main__desc">Explore more than 14,000 servers on discord</h6>
            <div className="search__bar mt-3 d-flex">
              <i className="fa fa-search"></i>
              <div id="search-box" className="w-100">
                <input type="text" className="w-100 search__input" placeholder="Search Server's" onChange={handleChange} value={message} />
                <div className="row" id="search" />
              </div>
                {
                  message !== null ? (
                    <Link to={`/discover/search/${message}`} style={{ color: '#FFF' }}><i className="fa fa-paper-plane-o"></i></Link>
                  ): (
                    <Link style={{ color: '#FFF' }}><i className="fa fa-paper-plane-o"></i></Link>
                  )
                }
            </div> 
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="tag__and__descreption">
                  <p className="descreption__tags">
                    You also can search by clicking the tag
                  </p>
                  <div className="all__tags">
                    <Link to={`/discover`}>
                      <p className="tag">All</p>
                    </Link>
                    {
                  loadingTypes ? (
                    <div className='row'>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                  </div>

                  ) : (
                  types.map((data) => (
                    <Link to={`/discover/types/${data}`} >
                      <p className="tag" onClick={shouldAddEventHandler ? GetServersByType : undefined}>{data}</p>
                    </Link>
                    
                    ))
                  )
                  }
                  <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="server__container mt-5">
            <h4 className="discover__title">Top Servers</h4>
            <div className="row mt-4">
              {
            loading ? (
              <Loading />
            ) : (

              TopServers.length === 0 ? (
                <center>Not Fund</center>
              ): 

              TopServers.map((data) => (

            <div className="col-md-4 my-3">
              <div className="card2 border-0 server__card">
                <img alt="img" src={ data.BANNER } className="img-fluid server__banner" />
                <div className="card-body mt-2">
                  <img alt="img" src={ data.ICON } width="85px" height="85px" className="server__icon" />
                  <Tooltip title="Total Vote" placement="bottom" disableFocusListener>
                    <span className="votes__number"><i className="fa fa-angle-up"></i> &nbsp; {data.COUNT_VOTE}</span>
                  </Tooltip>
                  {
                    (data.BADGES["PARTNER"] === 1 || data.BADGES["PREMIUM"] === 1 || data.BADGES["VERIFIED"] === 1) && (
                      <span className="badges_tag d-flex">
                        {data.BADGES["PARTNER"] === 1 && (
                          <Tooltip title="Partner" placement="bottom" disableFocusListener>
                            <img alt="img" height="18px" width="26px" src="https://cdn.discordapp.com/emojis/1076937098642346014.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["PREMIUM"] === 1 && (
                          <Tooltip title="Premium" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937105621659739.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["VERIFIED"] === 1 && (
                          <Tooltip title="Verified" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937092891955360.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                      </span>
                    )
                  }
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="text-start server__name mt-2">{ data.NAME }</h5>
                    </div>
                    <div className="col-md-3">
                      <div className="server__main__tag single-tag">
                      <p className="server__main__tag_2 single-tag">{ data.TYPE[0] }</p>
                          
                      {
                        data.TYPE[1] !== undefined ? (
                          <p className="server__main__tag_2 single-tag">{ data.TYPE[1] }</p>
                        ) : (
                          nothing
                        )
                      }
                      </div> 
                    </div>
                  </div>


                    <p className="text-start server_descreption"><ReactMarkdown
remarkPlugins={[remarkEmoji]}
>
{typeof data.DESCRIPTION === 'string' ? data.DESCRIPTION : JSON.stringify(data.DESCRIPTION)}
</ReactMarkdown></p></div>
                    <div className="row p-3">
                      <div className="col-sm-6">
                      <Link to={`/discover/view/${data.ID_SERVER}`}><button className="btn btn-join w-100 text-light">Join</button></Link>

                        </div>
                          <div className="col-sm-6">
                          <Link to={`/discover/view/${data.ID_SERVER}`} style={{ color: '#FFF' }}><button className="btn btn-vote w-100 text-light">View</button></Link>
                          </div>
                  </div>
                </div>
              </div>
          
               ))
            )
            }

          </div>
          </div>


          <div className="server__container mt-5">
            <h4 className="discover__title">Trending New Servers</h4>
            <div className="row mt-4">
              {
            LoadingNewServers ? (
              <Loading />
            ) : (

              DiscoverNewServers.map((data) => (

            <div className="col-md-4 my-3">
              <div className="card2 border-0 server__card">
                <img alt="img" src={ data.BANNER } className="img-fluid server__banner" />
                <div className="card-body mt-2">
                  <img alt="img" src={ data.ICON } width="85px" height="85px" className="server__icon" />
                  <Tooltip title="Total Vote" placement="bottom" disableFocusListener>
                    <span className="votes__number"><i className="fa fa-angle-up"></i> &nbsp; {data.COUNT_VOTE}</span>
                  </Tooltip>
                  {
                    (data.BADGES["PARTNER"] === 1 || data.BADGES["PREMIUM"] === 1 || data.BADGES["VERIFIED"] === 1) && (
                      <span className="badges_tag d-flex">
                        {data.BADGES["PARTNER"] === 1 && (
                          <Tooltip title="Partner" placement="bottom" disableFocusListener>
                            <img alt="img" height="18px" width="26px" src="https://cdn.discordapp.com/emojis/1076937098642346014.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["PREMIUM"] === 1 && (
                          <Tooltip title="Premium" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937105621659739.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["VERIFIED"] === 1 && (
                          <Tooltip title="Verified" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937092891955360.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                      </span>
                    )
                  }
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="text-start server__name mt-2">{ data.NAME }</h5>
                    </div>
                        <div className="col-md-3"><p className="server__main__tag_1">{ data.TYPE[0] }</p></div>     
                          {
                            data.TYPE[1] !== undefined ? (
                              <div className="col-md-3"><p className="server__main__tag_2">{ data.TYPE[1] }</p></div>
                            ) : (
                              nothing
                            )
                          }
                  </div>

                    <p className="text-start server_descreption">
                <ReactMarkdown
                  remarkPlugins={[remarkEmoji]}
                >
                  {typeof data.DESCRIPTION === 'string' ? data.DESCRIPTION : JSON.stringify(data.DESCRIPTION)}
                </ReactMarkdown>
                      </p></div>
                    <div className="row p-3">
                      <div className="col-sm-6">
                          <Link Link to={`/discover/view/${data.ID_SERVER}`}><button className="btn btn-join w-100 text-light">Join</button></Link>
                          </div>
                          <div className="col-sm-6"><Link to={`/discover/view/${data.ID_SERVER}`} style={{ color: '#FFF' }}><button className="btn btn-vote w-100 text-light">View</button></Link>
                    </div>
                  </div>
                </div>
              </div>
          
               ))
            )
            }

          </div>
          </div>


          <div className="server__container mt-5">
            <h4 className="discover__title">Community</h4>
            <div className="row mt-4">
              {
            LoadingType_1 ? (
              <Loading />
            ) : (

              DiscoverTypes1.length === 0 ? (
                <Loading />
              ): 

              DiscoverTypes1.map((data) => (

            <div className="col-md-4 my-3">
              <div className="card2 border-0 server__card">
                <img alt="img" src={data.BANNER} className="img-fluid server__banner" />
                <div className="card-body mt-2">
                  <img alt="img" src={data.ICON} width="85px" height="85px" className="server__icon" />
                  <Tooltip title="Total Vote" placement="bottom" disableFocusListener>
                    <span className="votes__number"><i className="fa fa-angle-up"></i> &nbsp; {data.COUNT_VOTE}</span>
                  </Tooltip>
                  {
                    (data.BADGES["PARTNER"] === 1 || data.BADGES["PREMIUM"] === 1 || data.BADGES["VERIFIED"] === 1) && (
                      <span className="badges_tag d-flex">
                        {data.BADGES["PARTNER"] === 1 && (
                          <Tooltip title="Partner" placement="bottom" disableFocusListener>
                            <img alt="img" height="18px" width="26px" src="https://cdn.discordapp.com/emojis/1076937098642346014.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["PREMIUM"] === 1 && (
                          <Tooltip title="Premium" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937105621659739.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["VERIFIED"] === 1 && (
                          <Tooltip title="Verified" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937092891955360.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                      </span>
                    )
                  }

                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="text-start server__name mt-2">{data.NAME}</h5>
                    </div>
                    <div className="col-md-3">
                      <p className="server__main__tag_1">{data.TYPE[0]}</p>
                    </div>
                    {data.TYPE[1] && (
                      <div className="col-md-3">
                        <p className="server__main__tag_2">{data.TYPE[1]}</p>
                      </div>
                    )}
                  </div>
                  <p className="text-start server_descreption">
                    <ReactMarkdown remarkPlugins={[remarkEmoji]}>
                      {typeof data.DESCRIPTION === 'string' ? data.DESCRIPTION : JSON.stringify(data.DESCRIPTION)}
                    </ReactMarkdown>
                  </p>
                </div>
                <div className="row p-3">
                  <div className="col-sm-6">
                  <Link to={`/discover/view/${data.ID_SERVER}`}><button className="btn btn-join w-100 text-light">Join</button></Link>
                  </div>
                  <div className="col-sm-6">
                    <Link to={`/discover/view/${data.ID_SERVER}`} style={{ color: '#FFF' }}>
                      <button className="btn btn-vote w-100 text-light">View</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          
               ))
            )
            }

          </div>
          </div>


          <div className="server__container mt-5">
            <h4 className="discover__title">Gaming</h4>
            <div className="row mt-4">
              {
            LoadingType_2 ? (
              <Loading />
            ) : (

              DiscoverTypes2.length === 0 ? (
                <Loading />
              ): 

              DiscoverTypes2.map((data) => (

            <div className="col-md-4 my-3">
              <div className="card2 border-0 server__card">
                <img alt="img" src={ data.BANNER } className="img-fluid server__banner" />
                <div className="card-body mt-2">
                  <img alt="img" src={ data.ICON } width="85px" height="85px" className="server__icon" />
                  <Tooltip title="Total Vote" placement="bottom" disableFocusListener>
                    <span className="votes__number"><i className="fa fa-angle-up"></i> &nbsp; {data.COUNT_VOTE}</span>
                  </Tooltip>
                  {
                    (data.BADGES["PARTNER"] === 1 || data.BADGES["PREMIUM"] === 1 || data.BADGES["VERIFIED"] === 1) && (
                      <span className="badges_tag d-flex">
                        {data.BADGES["PARTNER"] === 1 && (
                          <Tooltip title="Partner" placement="bottom" disableFocusListener>
                            <img alt="img" height="18px" width="26px" src="https://cdn.discordapp.com/emojis/1076937098642346014.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["PREMIUM"] === 1 && (
                          <Tooltip title="Premium" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937105621659739.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["VERIFIED"] === 1 && (
                          <Tooltip title="Verified" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937092891955360.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                      </span>
                    )
                  }
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="text-start server__name mt-2">{ data.NAME }</h5>
                    </div>
                        <div className="col-md-3"><p className="server__main__tag_1">{ data.TYPE[0] }</p></div>     
                          {
                            data.TYPE[1] !== undefined ? (
                              <div className="col-md-3"><p className="server__main__tag_2">{ data.TYPE[1] }</p></div>
                            ) : (
                              nothing
                            )
                          }
                  </div>

                    <p className="text-start server_descreption"><ReactMarkdown
remarkPlugins={[remarkEmoji]}
>
{typeof data.DESCRIPTION === 'string' ? data.DESCRIPTION : JSON.stringify(data.DESCRIPTION)}
</ReactMarkdown></p></div>
                    <div className="row p-3">
                      <div className="col-sm-6">
                      <Link to={`/discover/view/${data.ID_SERVER}`}><button className="btn btn-join w-100 text-light">Join</button></Link>
                          </div>
                          <div className="col-sm-6"><Link to={`/discover/view/${data.ID_SERVER}`} style={{ color: '#FFF' }}><button className="btn btn-vote w-100 text-light">View</button></Link>
                    </div>
                  </div>
                </div>
              </div>
          
               ))
            )
            }

          </div>
          </div>


        </div>
      </section>
                
        <Footer />
        
      </div> 
      
    )
}
