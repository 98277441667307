import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'


import { HomePage } from './pages/HomePage'
import { Shards } from './pages/shards'
import { Commands } from './pages/commands'
import { premium } from './pages/premium'

import { policy } from './pages/policy'
import { terms } from './pages/terms'
import { refund } from './pages/refund'

import { Discover } from './pages/discover'
import { Discovertypes } from './pages/discovertypes'
import { DiscoverSearch } from './pages/discoversearch'
import { DiscoverView } from './pages/discoverview'
import { NotFoundPage } from './component/NotFoundPage'

function App() {
  return (
    <Router>
    <Fragment>

    <Switch>

      <Route path="/" exact={ true } component={ HomePage } />
      <Route path="/shards" exact={ true } component={ Shards } />
      <Route path="/commands" exact={ true } component={ Commands } />
      <Route path="/premium" exact={ true } component={ premium } />
      <Route path="/policy" exact={ true } component={ policy } />
      <Route path="/refund" exact={ true } component={ refund } />
      <Route path="/terms" exact={ true } component={ terms } />
      <Route path="/discover" exact={ true } component={ Discover } />
      <Route path="/discover/types/:Type" exact={ true } component={ Discovertypes } />
      <Route path="/discover/search/:Name" exact={ true } component={ DiscoverSearch } />
      <Route path="/discover/view/:IDServer" exact={ true } component={ DiscoverView } />
      <Route component={NotFoundPage} />

    </Switch>
    </Fragment>
    </Router>
  );
}

export default App;
