const axios = require("axios");

const URL = "https://api.special-share.org"
// const URL = "http://localhost:8888"

export function GetDiscover() {
    return axios.get(`${URL}/api/discover`, { withCredentials: true })
}

export function GetDiscoverTypes(Type) {
    return axios.get(`${URL}/api/discover/types/${Type}`, { withCredentials: true })
}

export function GetDiscoverNewServers() {
    return axios.get(`${URL}/api/discover/NewServers`, { withCredentials: true })
}

export function GetDiscoverSearchServers(Name) {
    return axios.get(`${URL}/api/discover/search/${Name}`, { withCredentials: true })
}

export function GetTypes() {
    return axios.get(`${URL}/api/types`, { withCredentials: true })
}

export function GetServerByID(IDServer) {
    return axios.get(`${URL}/api/discover/view/${IDServer}`, { withCredentials: true })
}

export function GetShards() {
    return axios.get(`${URL}/api/shards`, { withCredentials: true })
}

export function GetStatusSession() {
    return axios.get(`${URL}/api/session`, { withCredentials: true })
}

export function GetStats() {
    return axios.get(`${URL}/api/stats`, { withCredentials: true })
}
export function CreateInvite(TUID, ID_SERVER) {
    return axios.post(`${URL}/api/createinvite`, { 
        withCredentials: true,
        ServerID: ID_SERVER,
        headers: { Authorization: `${TUID}` }
 })
}



export function ServerVoting(TUID, ReCAPTCHA, ID_SERVER) {
    return axios.post(`${URL}/api/vote`, { 
        withCredentials: true,
        ServerID: ID_SERVER,
        ReCAPTCHA: ReCAPTCHA,
        headers: { Authorization: `${TUID}` }
 })
}

