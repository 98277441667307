import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';
import '../../assets/css/servers.css';
import '../../assets/css/test.css';
import 'font-awesome/css/font-awesome.min.css';
import { Header } from '../../component/Header'
import { Footer } from '../../component/Footer'
import { Loading } from '../../component/loading'
import { GetTypes, GetDiscoverSearchServers } from '../../utilts/api'
import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import ReactMarkdown from 'react-markdown';
import remarkEmoji from 'remark-emoji';

export function DiscoverSearch(){

  const [ DiscoverSearchServers, SetDiscoverSearchServers ] = React.useState([])
  const [ types, setTypes ] = React.useState([])
  const [loading, setLoading] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);

  const [message, setMessage] = useState(null);
  const [GetName, setGetName] = useState(false);

  const UEL = window.location.href
  const CHECK_Search = UEL.substring(UEL.indexOf('/discover/search/') + 17);
  const CheckIFSearch = UEL.includes("search");

  if(CheckIFSearch && GetName === false){
    setGetName(true);
    setLoading(true);
    GetDiscoverSearchServers(CHECK_Search).then(({data}) => SetDiscoverSearchServers(data))
    .finally(() => {
      setLoading(false);
    });
  }

  const handleChange = event => {
    setMessage(event.target.value);
  };
  
  function SearchServer(){
    setLoading(true);
    GetDiscoverSearchServers(message).then(({data}) => SetDiscoverSearchServers(data))
    .finally(() => {
      setLoading(false);
    });
  }

  React.useEffect(() => {
    setLoadingTypes(true);
    

    GetTypes().then(({data}) => setTypes(data))
    .finally(() => {
      setLoadingTypes(false);
    });

    
  }, [])



    return(

    <div>
        <Header />
        
        <section>
        <div className="container2 w-75">
          <div className="filter__container mt-5">
            <h6 className="main__desc">Explore more than 14,000 servers on discord</h6>
            <div className="search__bar mt-3 d-flex">
              <i class="fa fa-search"></i>
              <div id="search-box" className="w-100">
                <input type="text" className="w-100 search__input" placeholder="Search Server's" onChange={handleChange} value={message}/>
                <div className="row" id="search" />
              </div>
                {
                  message !== null ? (
                    <Link onClick={SearchServer} style={{ color: '#FFF' }}><i className="fa fa-paper-plane-o"></i></Link>
                  ): (
                    <Link style={{ color: '#FFF' }}><i className="fa fa-paper-plane-o"></i></Link>
                  )
                }


            </div> 
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="tag__and__descreption">
                  <p className="descreption__tags">
                    You also can search by clicking the tag
                  </p>
                  <div className="all__tags">
                    <Link to={`/discover`}>
                      <p className="tag"  >All</p>
                    </Link>
                    {
                  loadingTypes ? (
                    <div className='row'>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                    <div className="skeleton_types"></div>
                  </div>

                  ) : (
                  types.map((data) => (
                    <Link to={`/discover/types/${data}`} >
                      <p className="tag" >{data}</p>
                    </Link>
                    
                    ))
                  )
                  }
                  <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>

{
  
          <div className="server__container mt-5">
            <h4 className="discover__title">Search Server</h4>
            <div className="row mt-4">
              {
            loading ? (
              <Loading />
            ) : (

              DiscoverSearchServers.length === 0 ? (
                <center style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', flexDirection: 'column' }}>
                  <i className="fa fa-exclamation-triangle" style={{ fontSize: '50px', color: '#ff4aa1' }}></i>
                  <h2 style={{ color: '#ff4aa1', marginTop: '20px' }}>No Results Found</h2>
                  <Link to="/discover" style={{ textDecoration: 'none' }}>
                    <button style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#ff4aa1', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                      <i className="fa fa-arrow-left" style={{ marginRight: '10px' }}></i>
                      Back
                    </button>
                  </Link>
                </center>
              ): 

              DiscoverSearchServers.map((data) => (

            <div className="col-md-4 my-3">
              <div className="card2 border-0 server__card">
                <img alt="img" src={ data.BANNER } className="img-fluid server__banner" />
                <div className="card-body mt-2">
                  <img alt="img" src={ data.ICON } width="85px" height="85px" className="server__icon" />
                  <Tooltip title="Total Vote" placement="bottom" disableFocusListener>
                    <span className="votes__number"><i className="fa fa-angle-up"></i> &nbsp; {data.COUNT_VOTE}</span>
                  </Tooltip>
                  {
                    (data.BADGES["PARTNER"] === 1 || data.BADGES["PREMIUM"] === 1 || data.BADGES["VERIFIED"] === 1) && (
                      <span className="badges_tag d-flex">
                        {data.BADGES["PARTNER"] === 1 && (
                          <Tooltip title="Partner" placement="bottom" disableFocusListener>
                            <img alt="img" height="18px" width="26px" src="https://cdn.discordapp.com/emojis/1076937098642346014.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["PREMIUM"] === 1 && (
                          <Tooltip title="Premium" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937105621659739.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                        {data.BADGES["VERIFIED"] === 1 && (
                          <Tooltip title="Verified" placement="bottom" disableFocusListener>
                            <img alt="img" height="20px" width="20px" src="https://cdn.discordapp.com/emojis/1076937092891955360.webp?size=96" className="badge_icon" />
                          </Tooltip>
                        )}
                      </span>
                    )
                  }
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="text-start server__name mt-2">{ data.NAME }</h5>
                    </div>
                    <div className="col-md-3">
                      <div className="server__main__tag">
                      <p class="server__main__tag_2">{ data.TYPE[0] }</p>
                          
                      {
                        data.TYPE[1] !== null ? (
                          <p class="server__main__tag_2">{ data.TYPE[1] }</p>
                        ) : (
                          <h1></h1>
                        )
                      }
                      </div> 
                    </div>
                  </div>
                    <p className="text-start server_descreption">
                <ReactMarkdown
                  remarkPlugins={[remarkEmoji]}
                >
                  {data.DESCRIPTION}
                </ReactMarkdown>
                      </p></div>
                    <div className="row p-3">
                      <div className="col-sm-6">
                        <a href="https://discord.gg/&quot;+val.Invite+&quot;">
                          <Link to={`/discover/view/${data.ID_SERVER}`}><button className="btn btn-join w-100 text-light">Join</button></Link></a></div>
                          <div className="col-sm-6"><Link to={`/discover/view/${data.ID_SERVER}`} style={{ color: '#FFF' }}><button className="btn btn-vote w-100 text-light">View</button></Link>
                    </div>
                  </div>
                </div>
              </div>
          
               ))
            )
            }

          </div>
          </div>
}      

  
      


        </div>
      </section>
                
        <Footer />
        
      </div> 
      
    )
}
