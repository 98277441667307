import React from 'react'
// import { getUserDetals } from '../../utilts/api'
// import { Link } from 'react-router-dom'
import '../../assets/css/main.css';
import '../../assets/css/animations.css';
import '../../assets/css/responsivefixes.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import Tilt from 'react-tilt'
import 'font-awesome/css/font-awesome.min.css';
import CountUp from 'react-countup';
import { Header } from '../../component/Header'
import { Footer } from '../../component/Footer'
import Tooltip from '@mui/material/Tooltip';
// import { useCookies } from 'react-cookie';
import { GetStats } from '../../utilts/api'

export function HomePage(){
  const [ Stats, SetStats ] = React.useState([])
  

  React.useEffect(() => {
    GetStats().then(({data}) => SetStats(data[0]))
  }, [])

  // const [cookies] = useCookies()

  // function openDiscord(){
  //   window.open("https://discord.gg/dis-ar", '_blank');
  // };

  function ConvertNumber(value) {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "k", "m", "b","t"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
}


    return(

    <div>
        <Header />
        
        <section>
          <div className="container typography-container">
            <div className="row">
              <div className="col-lg-6 my-2">
                <div className="shadow-back" />
                <span className="tag-descreption">Sharing &amp; NEW Communites</span>
                <h1 className="main-title mt-2 fw-bolder">Your Way To Reach Your <br />  Dream Members Count</h1>
                <p className="title-descreption w-75 mt-4">Bot Special Share For Share Your Server To Further Expand Your Server 🌟</p>
                <div className="buttons-category mt-5">
                  <button className="btn btn-Invite text-light" onClick={() => {window.open('https://special-share.org/invite','popUpWindow','height=800,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'); }}><svg class="svg-inline--fa fa-discord fa-w-14" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M297.216 243.2c0 15.616-11.52 28.416-26.112 28.416-14.336 0-26.112-12.8-26.112-28.416s11.52-28.416 26.112-28.416c14.592 0 26.112 12.8 26.112 28.416zm-119.552-28.416c-14.592 0-26.112 12.8-26.112 28.416s11.776 28.416 26.112 28.416c14.592 0 26.112-12.8 26.112-28.416.256-15.616-11.52-28.416-26.112-28.416zM448 52.736V512c-64.494-56.994-43.868-38.128-118.784-107.776l13.568 47.36H52.48C23.552 451.584 0 428.032 0 398.848V52.736C0 23.552 23.552 0 52.48 0h343.04C424.448 0 448 23.552 448 52.736zm-72.96 242.688c0-82.432-36.864-149.248-36.864-149.248-36.864-27.648-71.936-26.88-71.936-26.88l-3.584 4.096c43.52 13.312 63.744 32.512 63.744 32.512-60.811-33.329-132.244-33.335-191.232-7.424-9.472 4.352-15.104 7.424-15.104 7.424s21.248-20.224 67.328-33.536l-2.56-3.072s-35.072-.768-71.936 26.88c0 0-36.864 66.816-36.864 149.248 0 0 21.504 37.12 78.08 38.912 0 0 9.472-11.52 17.152-21.248-32.512-9.728-44.8-30.208-44.8-30.208 3.766 2.636 9.976 6.053 10.496 6.4 43.21 24.198 104.588 32.126 159.744 8.96 8.96-3.328 18.944-8.192 29.44-15.104 0 0-12.8 20.992-46.336 30.464 7.68 9.728 16.896 20.736 16.896 20.736 56.576-1.792 78.336-38.912 78.336-38.912z"></path></svg> &nbsp; Invite SpecialShare</button>
                  
                  {/* { */}
                    {/* cookies.TUID !== undefined ? ( */}
                      <button className="btn btn-dashboard text-light mx-2" onClick={() => {window.open('http://special-share.org/dashboard'); }}><svg class="svg-inline--fa fa-browser fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="browser" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM48 92c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V92zm416 334c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V168h416v258zm0-310c0 6.6-5.4 12-12 12H172c-6.6 0-12-5.4-12-12V92c0-6.6 5.4-12 12-12h280c6.6 0 12 5.4 12 12v24z"></path></svg> &nbsp; Enter To Dashboard</button>
                     {/* ): ( */}
                      {/* <button className="btn btn-dashboard text-light mx-2" onClick={() => {window.open(''); }}><svg class="svg-inline--fa fa-browser fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="browser" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM48 92c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V92zm416 334c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V168h416v258zm0-310c0 6.6-5.4 12-12 12H172c-6.6 0-12-5.4-12-12V92c0-6.6 5.4-12 12-12h280c6.6 0 12 5.4 12 12v24z"></path></svg> &nbsp; Login To Dashboard</button> */}
                     {/* )
                   } */}
                  
                </div>
              </div>
              <div className="col-lg-6 my-2">
                <img src="https://special-share.org/assets/images/dashboard.png" alt="img" className="img-fluid main-image mt-4 slide-in-elliptic-top-fwd upToDown" />
              </div>
            </div>
          </div>
        </section>
          
        <section className="staics-section p-5">
          <p className="statics-tag text-center position-relative">SpecialShare Statics</p>
          <h4 className="text-center fw-bolder">Trusted By More Than {ConvertNumber(Stats.GUILD)} Servers</h4>
          <p className="text-center" style={{color: '#b0b4c7'}}>Some popular servers that use bot</p>
          <center>
            <div className="row st-row w-50">
              <div className="col-md-4 my-1">
                <div className="card card-server sr-1 my-2 p-4">
                  <img className="rounded img-fluid server-image" alt="img" src="https://i.imgur.com/eFOKvQ0.png" />
                  <div className="server-info d-inline-block">
                    <h6 className="text-light">Who is here?</h6>
                    <span className="server-info"><FontAwesomeIcon icon={faUser} /> 0</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 my-1">
                <div className="card card-server sr-2 my-2 p-4">
                  <img className="rounded img-fluid server-image" alt="img" src="https://i.imgur.com/3d4558V.gif" />
                  <div className="server-info d-inline-block">
                    <h6 className="text-light">Arabic Discord&nbsp;
                    <Tooltip title="Partner" disableFocusListener>
                    <img className="verified-badge" id="partner-ver" alt="img" src="https://cdn.discordapp.com/emojis/858344375042310214.png?v=1" width="23px" height="17px" />
                    </Tooltip>
                    </h6>
                    <span className="server-info "><FontAwesomeIcon icon={faUser} /> 100K</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 my-1">
                <div className="card card-server sr-2 my-2 p-4">
                  <img className="rounded img-fluid server-image" alt="img" src="https://i.imgur.com/eFOKvQ0.png" />
                  <div className="server-info d-inline-block">
                    <h6 className="text-light">Who is here?&nbsp;
                    {/* <Tooltip title="Partner" disableFocusListener> */}
                    {/* <img className="verified-badge" id="partner-ver" alt="img" src="https://cdn.discordapp.com/emojis/858344375042310214.png?v=1" width="23px" height="17px" /> */}
                    {/* </Tooltip> */}
                    </h6>
                    <span className="server-info "><FontAwesomeIcon icon={faUser} /> 0</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card stats-box mt-3 p-3">
              <div className="row">
                <div className="col-md-4">
                <svg class="svg-inline--fa fa-users fa-w-20 stats-icon" aria-hidden="true" focusable="false" data-prefix="far" data-icon="users" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-112c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-112c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zm396.4 210.9c-27.5-40.8-80.7-56-127.8-41.7-14.2 4.3-29.1 6.7-44.7 6.7s-30.5-2.4-44.7-6.7c-47.1-14.3-100.3.8-127.8 41.7-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c.2-23.8-7-45.9-19.4-64.3zM464 432H176v-44.8c0-36.4 29.2-66.2 65.4-67.2 25.5 10.6 51.9 16 78.6 16 26.7 0 53.1-5.4 78.6-16 36.2 1 65.4 30.7 65.4 67.2V432zm92-176h-24c-17.3 0-33.4 5.3-46.8 14.3 13.4 10.1 25.2 22.2 34.4 36.2 3.9-1.4 8-2.5 12.3-2.5h24c19.8 0 36 16.2 36 36 0 13.2 10.8 24 24 24s24-10.8 24-24c.1-46.3-37.6-84-83.9-84zm-236 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-176c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zM154.8 270.3c-13.4-9-29.5-14.3-46.8-14.3H84c-46.3 0-84 37.7-84 84 0 13.2 10.8 24 24 24s24-10.8 24-24c0-19.8 16.2-36 36-36h24c4.4 0 8.5 1.1 12.3 2.5 9.3-14 21.1-26.1 34.5-36.2z"></path></svg>
                  <h4 className="mt-3 fw-bolder count"><CountUp end={Stats.USERS} duration={2.75} /></h4>
                  <p className="statics-title-sm">Total Users</p>
                </div>
                <div className="col-md-4">
                <svg class="svg-inline--fa fa-server fa-w-16 stats-icon" aria-hidden="true" focusable="false" data-prefix="far" data-icon="server" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M424 400c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24zm-88-24c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm64-144c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm176-72a47.758 47.758 0 0 1-6.438 24A47.758 47.758 0 0 1 512 208v96a47.758 47.758 0 0 1-6.438 24A47.758 47.758 0 0 1 512 352v96c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48v-96a47.758 47.758 0 0 1 6.438-24A47.758 47.758 0 0 1 0 304v-96a47.758 47.758 0 0 1 6.438-24A47.758 47.758 0 0 1 0 160V64c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v96zm-464 0h416V64H48v96zm416 48H48v96h416v-96zm0 144H48v96h416v-96zm-64-216c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24zm-64 0c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z"></path></svg>
                  <h4 className="mt-3 fw-bolder count"><CountUp end={Stats.GUILD} duration={2.75} /></h4>
                  <p className="statics-title-sm">Online Servers</p>
                </div>
                <div className="col-md-4">
                <svg class="svg-inline--fa fa-share fa-w-18 stats-icon" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="share" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M564.907 196.35L388.91 12.366C364.216-13.45 320 3.746 320 40.016v88.154C154.548 130.155 0 160.103 0 331.19c0 94.98 55.84 150.231 89.13 174.571 24.233 17.722 58.021-4.992 49.68-34.51C100.937 336.887 165.575 321.972 320 320.16V408c0 36.239 44.19 53.494 68.91 27.65l175.998-184c14.79-15.47 14.79-39.83-.001-55.3zm-23.127 33.18l-176 184c-4.933 5.16-13.78 1.73-13.78-5.53V288c-171.396 0-295.313 9.707-243.98 191.7C72 453.36 32 405.59 32 331.19 32 171.18 194.886 160 352 160V40c0-7.262 8.851-10.69 13.78-5.53l176 184a7.978 7.978 0 0 1 0 11.06z"></path></svg>
                  <h4 className="mt-3 fw-bolder count"><CountUp end={Stats.SHARE} duration={2.75} /></h4>
                  <p className="statics-title-sm">Shared Servers</p>
                </div>
              </div>
            </div>
          </center>
        </section>


        <section>
          <div className="container" style={{marginTop: '5%'}}>
            <div className="features p-2">
              <center>  
                <h1 className="fw-bolder features-main-title">FEATURES</h1>

                <Tooltip title="Dashbaord" placement="left" disableFocusListener>
                <div id="fet1" className="rounded-circle feature-circle d-inline-block m-5"><svg class="svg-inline--fa fa-browser fa-w-16 ft-ic" aria-hidden="true" focusable="false" data-prefix="far" data-icon="browser" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM48 92c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V92zm416 334c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V168h416v258zm0-310c0 6.6-5.4 12-12 12H172c-6.6 0-12-5.4-12-12V92c0-6.6 5.4-12 12-12h280c6.6 0 12 5.4 12 12v24z"></path></svg></div>
                </Tooltip>

                <Tooltip title="Chart" placement="top-start" disableFocusListener>
                <div id="fet2" className="rounded-circle feature-circle d-inline-block m-5"><svg class="svg-inline--fa fa-chart-pie-alt fa-w-16 ft-ic" aria-hidden="true" focusable="false" data-prefix="far" data-icon="chart-pie-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M461.29 288H224V50.71c0-8.83-7.18-16.21-15.74-16.21-.7 0-1.4.05-2.11.15C87.08 51.47-3.96 155.43.13 280.07 4.2 404.1 107.91 507.8 231.93 511.87c2.69.09 5.39.13 8.07.13 121.04 0 220.89-89.66 237.35-206.16 1.33-9.45-6.52-17.84-16.06-17.84zM240 464c-2.15 0-4.33-.04-6.5-.11-98.98-3.25-182.15-86.42-185.4-185.4C45.31 193.22 98.36 118.35 176 91.14V336h244.78C394.15 411.06 322.06 464 240 464zM288.8.04c-.35-.03-.7-.04-1.04-.04C279.1 0 272 7.44 272 16.23V240h223.77c9.14 0 16.82-7.69 16.2-16.8C503.72 103.74 408.26 8.28 288.8.04z"></path></svg></div>
                </Tooltip>

                <Tooltip title="Protection" placement="right" disableFocusListener>
                <div id="fet3" className="rounded-circle feature-circle d-inline-block m-5"><svg class="svg-inline--fa fa-shield-check fa-w-16 ft-ic" aria-hidden="true" focusable="false" data-prefix="far" data-icon="shield-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M163.2 230.5c-4.7-4.7-12.3-4.7-17-.1l-22.7 22.5c-4.7 4.7-4.7 12.3-.1 17l90.8 91.5c4.7 4.7 12.3 4.7 17 .1l172.6-171.2c4.7-4.7 4.7-12.3.1-17l-22.5-22.7c-4.7-4.7-12.3-4.7-17-.1L223 290.7zM466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256 464C158.5 423.4 64 297.3 64 128l192-80 192 80c0 173.8-98.4 297-192 336z"></path></svg></div>
                </Tooltip>

              </center>
            </div>
          </div>
          <div className="container" style={{marginTop: '7%'}}>
            <div className="row align-items-center">
              <div className="col-lg-5 p-5 my-2">
                <lord-icon className="navbar-logo rounded-circle position-relative" src="https://cdn.lordicon.com/wloilxuq.json" trigger="loop" colors="primary:#e4e4e4,secondary:#ff4aa1" style={{width: '80px', height: 'auto'}}>
                </lord-icon>
                <h4 className="feature-title fw-bolder">This is how the server share looks</h4>
                <p className="text-start feature-descreption">You can customize the appearance of your server by setting a beautiful banner and description</p>
              </div>
              <div className="col-lg-7 my-2">
                <div className="images">
                  <Tilt options={{ max : 20, scale: 1.1, speed: 1000 }} style={{ height: 0, width: 250 }} >
                    <img className="main_image" src="https://special-share.org/assets/images/em_1.png" alt="img"  draggable="false" />
                  </Tilt>
                  <img src="https://special-share.org/assets/images/em_2.png" alt="" draggable="false" />
                </div>
              </div>
            </div>
            <div className="row align-items-center" style={{marginTop: '9%'}}>
              <div className="col-lg-6 my-2">
                <div className="parent">
                  <Tilt options={{ max : 20, scale: 1.1, speed: 1000 }} style={{ position: "relative", top: 40, left: -12, width: 340}}>
                    <img data-tilt data-tilt-scale="1.2" data-tilt-speed={1000} src="https://special-share.org/assets/images/leave.png" className="img-fluid leave" draggable="false" alt="img" />
                  </Tilt>
                  <Tilt options={{ max : 20, scale: 1.1, speed: 1000 }} className="img-fluid join">
                    <img data-tilt data-tilt-scale="1.2" data-tilt-speed={1000} src="https://special-share.org/assets/images/join.png" className="img-fluid join" draggable="false" alt="img" />
                  </Tilt>
                </div>
              </div>
              <div className="col-lg-6 p-5 my-2">
                <lord-icon className="navbar-logo rounded-circle position-relative" src="https://cdn.lordicon.com/gqdnbnwt.json" trigger="loop" colors="primary:#e4e4e4,secondary:#ff4aa1" style={{width: '80px', height: 'auto'}}>
                </lord-icon>
                <h4 className="feature-title fw-bolder w-75">Full stats about your server</h4>
                <p className="text-start feature-descreption w-75">Statistics through which you can see the number of members of your dreams is increasing (:</p>
              </div>
            </div>
            <div className="row align-items-center" style={{marginTop: '9%'}}>
              <div className="col-lg-6 p-5 my-2">
                <lord-icon className="navbar-logo rounded-circle position-relative" src="https://cdn.lordicon.com/fgkmrslx.json" trigger="loop" colors="primary:#e4e4e4,secondary:#ff4aa1" style={{width: '80px', height: 'auto'}}>
                </lord-icon>
                <h4 className="feature-title fw-bolder">You can buy a banner to increase the beauty of your server's engagement</h4>
                <p className="text-start feature-descreption w-75">You can buy a banner that suits the look of your server More than 50+ banner are available</p>
              </div>
              <div className="col-lg-6 my-2">
                <center>

                  <Tilt options={{ max : 20, scale: 1.6, speed: 900 }} className="img-fluid bannern">
                    <img src="https://special-share.org/assets/images/00.png" className="img-fluid banner" width="600px" height="auto" draggable="false" alt="img" />
                  </Tilt>

                  <Tilt options={{ max : 20, scale: 1.5, speed: 900 }} className="img-fluid banner" scale="1.5" speed="1000">
                    <img src="https://special-share.org/assets/images/11.png" className="img-fluid banner" width="600px" height="auto" draggable="false" alt="img" />
                  </Tilt>

                  <Tilt options={{ max : 20, scale: 1.3, speed: 900 }} className="img-fluid banner" scale="1.3" speed="1000">
                    <img src="https://special-share.org/assets/images/22.png" className="img-fluid banner" width="600px" height="auto" draggable="false" alt="img" />
                  </Tilt>

                  <Tilt options={{ max : 20, scale: 1.2, speed: 900 }} className="img-fluid banner" scale="1.2" speed="1000">
                    <img src="https://special-share.org/assets/images/33.png" className="img-fluid banner" width="600px" height="auto" draggable="false" alt="img" />
                  </Tilt>

                </center>
              </div>
            </div>
          </div>
        </section>

        <section className="partners-section p-5">
          <div className="container">
            <h2 className="text-center features-title">Our Partners</h2>
            <p className="text-center fw-bolder">Are you ready to be a partner? <a href="/support" target="_blank" className="link-footer">Apply Here</a></p>
            <center><div className="features-main-title center" /></center><br />
            <center>
              <div className="row mt-5">
                {/* <div class="col-md-3 my-2">
                </div> */}
                {/* <div className="col-md-3 my-1">
                  <div className="card border-0 partner-card">
                    <img alt="img" src="https://i.imgur.com/YnjJnYl.png" className="img-fluid partner-banner" width="100%" data-sr-id={11} style={{visibility: 'visible', opacity: 1, transition: 'opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0.4s'}} />
                    <div className="card-body mt-2">
                      <h5 className="text-start mt-1">Discord Listing <Tooltip title="Partner" placement="right" disableFocusListener><img alt="" id="partner-ver" className="verified-badge" src="https://cdn.discordapp.com/emojis/858344375042310214.png?v=1" width="23px" height="auto" /></Tooltip></h5>
                      <p className="text-start partner-descreption mt-3"> If you are looking for a fantastic way to **grow your server member count**, check out Discord Listing.</p>
                    </div>
                    <div className="card-footer p-3">
                      <button className="btn btn-view w-100 text-light" onclick="window.open('https://discordlisting.com/?utm_source=SpecialShare&utm_medium=DiscordBot')">View Discord Listing</button>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-3 my-1">
                  <div className="card border-0 partner-card">
                    <img alt="img" src="https://i.imgur.com/ThY2Er7.png" className="img-fluid partner-banner" width="100%" data-sr-id={11} style={{visibility: 'visible', opacity: 1, transition: 'opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0.4s'}} />
                    <div className="card-body mt-2">
                      <h5 className="text-start mt-1">GiveawaysBot <Tooltip title="Partner" placement="right" disableFocusListener><img alt="" id="partner-ver" className="verified-badge" src="https://cdn.discordapp.com/emojis/858344375042310214.png?v=1" width="23px" height="auto" /></Tooltip></h5>
                      <p className="text-start partner-descreption mt-3"> Start creating giveaways using our bot or from our dashboard without facing any problems. Design your giveawayswith the way you like.</p>
                    </div>
                    <div className="card-footer p-3">
                      <button className="btn btn-view w-100 text-light" onclick="window.open('https://giveawaysb.cc/')">View GiveawaysBot</button>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-3 my-1">
                  <div className="card border-0 partner-card">
                    <img alt="img" src="https://dlist.dev/img/meta.png" className="img-fluid partner-banner" width="100%" data-sr-id={12} style={{visibility: 'visible', opacity: 1, transition: 'opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0.4s'}} />
                    <div className="card-body mt-2">
                      <h5 className="text-start mt-1">Dev list <Tooltip title="Partner" placement="right" disableFocusListener><img alt="" id="partner-ver" className="verified-badge" src="https://cdn.discordapp.com/emojis/858344375042310214.png?v=1" width="23px" height="auto" /></Tooltip></h5>
                      <p className="text-start partner-descreption mt-3"> (Developers List) Profiles for developers to link their projects, create teams &amp; improve their skills and show others their creativity</p>
                    </div>
                    <div className="card-footer p-3">
                      <button className="btn btn-view w-100 text-light" onclick="window.open('https://dlist.dev')">View Devlist</button>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-3 my-1">
                  <div className="card border-0 partner-card">
                    <img alt="img" src="https://i.imgur.com/01ZiTpW.jpg" className="img-fluid partner-banner" width="100%" data-sr-id={13} style={{visibility: 'visible', opacity: 1, transition: 'opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0.4s'}} />
                    <div className="card-body mt-2">
                      <h5 className="text-start mt-1">Arabic Discord <Tooltip title="Partner" placement="right" disableFocusListener><img alt="img" id="partner-ver" className="verified-badge" src="https://cdn.discordapp.com/emojis/858344375042310214.png?v=1" width="23px" height="auto" /></Tooltip></h5>
                      <p className="text-start partner-descreption mt-0">Arab Discord, we hope to present everything that is positive and useful to the Arab community within the framework of the Discord program</p>
                    </div>
                    <div className="card-footer p-3">
                      <a className="btn btn-view w-100 text-light" href="https://discord.gg/dis-ar">Join Arabic Discord</a>
                    </div>
                  </div>
                </div>

              </div>
              <br /><br />
            </center>
          </div>
        </section>
                
        <Footer />
        
      </div> 
    )
}